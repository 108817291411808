import React, { Component } from 'react';

import { Route, Link, Switch, Redirect, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import {
  // isBrowser,
  isMobile,
} from 'react-device-detect';

import ReactGA from 'react-ga';

import ReCAPTCHA from 'react-google-recaptcha';

import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import SaveIcon from '@material-ui/icons/Save';
// import MdStar from '@material-ui/icons/Star';
// import MdStarBorder from '@material-ui/icons/StarBorder';
import MdEmail from '@material-ui/icons/Email';
import MdLock from '@material-ui/icons/Lock';
// import MdStarBorder from 'react-icons/lib/md/star-border';
// import MdStar from 'react-icons/lib/md/star';
// import MdEmail from 'react-icons/lib/md/email';
// import MdLock from 'react-icons/lib/md/lock';
import MenuIcon from '@material-ui/icons/Menu';
import SchoolIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
// import ButtonComponent from '../src/components/common/ButtonComponent'
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
  withTheme,
} from '@material-ui/core/styles';
import ButtonComponent from './components/common/ButtonComponent';
import TextFieldComponent from './components/common/TextFieldComponent';
import ErrorBoundary from './ErrorBoundary';
// import { SocialIcon } from 'react-social-icons';

// import TextField from '@material-ui/core/TextField';

// import axios from 'axios';
// import moment from 'moment';
// import logo from './fylogo.svg';
import logo_white from './fylogo_white.svg';
import logo_primary from './fylogo_primary_color.svg';
import appleLogo from './apple.svg';
import successMark from './success.svg';
import googleLogo from './google.svg';
import keyIcon from './key.svg';
import envelopeIcon from './envelope.svg';
import {
  UtilFX,
  SlimWrapper,
  Wrapper,
  Colors,
  GlobalContext,
  GlobalStyles,
  AlertBox,
  DataFX,
  GifLoader,
  DefaultProfilePhotoUrl,
  ConvoMetadata,
  requestFirebaseNotificationPermission,
} from './utils';
import './App.css';
import {
  auth,
  database,
  firebase,
  firestore,
  // analytics,
} from './fire';
import ProfileOutside from './components/ProfileOutside';
import Home from './components/Home';
import Teachers from './components/Teachers';
import ClassesEditContainer from './components/containers/ClassesEditContainer';
import ClassesManageContainer from './components/containers/ClassesManageContainer';
import Students from './components/Students';
import ComponentGallery from './components/ComponentGallery';
import Profile from './components/Profile';
import ClassesDetailX from './components/ClassesDetail';
import Settings from './components/Settings';
import LearnMore from './components/LearnMore';
import LearnMoreTeacher from './components/LearnMoreTeacher';
import Schedule from './components/Schedule';
import Invites from './components/Invites';
import Inbox from './components/Inbox';
import Earnings from './components/PaymentEarnings';
import History from './components/PaymentHistory';
// import TeacherTools from './components/TeacherTools';
import Explore from './components/Explore';
import PlaceDetail from './components/PlaceDetail';
import ExploreTeachers from './components/ExploreTeachers';
import LimitsAndPlans from './components/LimitsAndPlans';
import NewInbox from './components/NewInbox';
import Alerts from './components/containers/AlertsContainer';
import GroupChatInvitePrompt from './components/GroupChatInvitePrompt';
import DeleteAccount from './components/DeleteAccount';
import AvatarDisplay from './components/AvatarDisplay';
// import Schedule from './components/Schedule';

// import { auth } from 'firebase';
// class ButtonCustom extends Component {
//   render() {
//     const { children } = this.props;
//     return (<Button  {...this.props}>{children}</Button>);
//   }
// }

// All the following keys are optional.
// We try our best to provide a great default value.
// const theme = createTheme({
//   palette: {
//     primary: indigo,
//     secondary: pink,
//     error: red,
//     // Used by `getContrastText()` to maximize the contrast between the background and
//     // the text.
//     contrastThreshold: 3,
//     // Used to shift a color's luminance by approximately
//     // two indexes within its tonal palette.
//     // E.g., shift from Red 500 to Red 300 or Red 700.
//     tonalOffset: 0.2,
//   },
// });
//   pri_color: "#1fa3ff",
//   sec_color: "#0e81ee"

const stylesProfile = (theme) => ({
  favbutton: {
    // '&:active': {
    //   background: "red",
    // },
    width: '80%',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:focus': {
      background: theme.palette.primary.main,
    },
  },
});
const themeX = createTheme({
  palette: {
    primary: {
      main: '#1fa3ff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#0e81ee',
      contrastText: '#fff',
    },
    // error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    // contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
    text: {
      primary: '#fff',
      secondary: 'rgba(0,0,0,0.4)',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      // "GillSans",
      // "Gill Sans",
      'GillSans-Medium',
      'Gill Sans medium',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // fontWeightMedium: 500,
    body1: {
      // fontWeight: 500
      fontSize: 14,
    },
    body2: {
      // fontWeight: 500
      fontSize: 14,
    },
    headline: {},
    h1: {
      fontWeight: 400,
      marginLeft: '-.02em',
      letterSpacing: '-.02em',
    },
    subheading: {},
    button: {},
  },
  // props: {
  //   MuiTextField: {
  //     variant: 'filled',
  //   },
  // },
  overrides: {
    // Name of the component ⚛️ / style shee
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#fff',
        backgroundColor: '#1fa3ff',
        // borderRadius: 100,
        border: '1px white solid',
        // height: 48,
        minWidth: '88px',
        padding: '5px 20px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .30)',
      },
      label: {
        textTransform: 'capitalize',
        /* Fav Yogi: */
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: '500',
        // letterSpacing: "0.99px",
        textAlign: 'center',
      },
      textSizeLarge: {
        padding: '8px 24px',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'inherit',
        fontSize: '1.75em',
      },
    },
    MuiGrid: {
      item: {
        // Grid-md-4
        flexBasis: '83.33333%',
        maxWidth: '83.333333%',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1em',
        color: 'green',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1em',
        color: 'yellow',
      },
    },
    // MuiInputOutlined: {
    //   root: {
    //     borderColor: "red"
    //   },
    //   outlined: {
    //     borderColor: "yellow"
    //   }
    // },
    MuiInput: {
      root: {
        fontSize: '1.5em',
      },
      underline: {
        // borderColor: "#fff",
        // borderBottom: "4px yellow solid",
        style: {
          // background: '#ffffff88',
          // borderColor: "#fff",
          // borderBottom: "4px red solid",
        },
        '&:before': {
          //underline color when textfield is inactive
          borderBottom: '4px #ffffff88 dashed',
        },
        '&:after': {
          //underline color when textfield is inactive
          borderBottom: '4px #fff dashed',
        },
        '&:hover': {
          //underline color when textfield is inactive
          borderBottom: '4px white dashed',
          // backgroundColor: "#ffffff22",
        },
      },
    },
  },
});

const ClassesDetail = withStyles(stylesProfile)(ClassesDetailX);

const ProfileBase = withTheme(withStyles(stylesProfile)(ProfileOutside));

const PrivateRoute = ({
  component: Component,
  wrapper: CustomWrapper,
  authed,
  ...rest
}) => {
  const PageWrapper = CustomWrapper || SlimWrapper;
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <PageWrapper wrapper>
            <Component {...props} {...rest} />
          </PageWrapper>
        ) : (
          <PageWrapper wrapper>
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          </PageWrapper>
        )
      }
    />
  );
};

export class LoginSignupForgotAuthUI extends Component {
  constructor(props, context) {
    super(props, context);
    // console.log("props and context", props, context);
    this.state = {
      email: '',
      pword: '',
      pwordconfirm: '',
      showBasicEmailPasswordForm: false,
      loader: false,
      hidePassword: false,
      hidePassword2: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    const { context } = this.props;
    //if there is a token use it to login
    const qd = {};
    if (window.location.search) {
      window.location.search
        .substr(1)
        .split('&')
        .forEach((item) => {
          const s = item.split('=');
          const k = s[0];
          const v = s[1] && decodeURIComponent(s[1]); //  null-coalescing / short-circuit
          //(k in qd) ? qd[k].push(v) : qd[k] = [v]
          (qd[k] = qd[k] || []).push(v); // null-coalescing / short-circuit
        });
    }

    const tok = qd.tokenforig && qd.tokenforig[0];
    const igState = qd.state && qd.state[0];
    // this.instagramStateReturned = igState;
    if (tok && tok.length) {
      context.updateAppState(
        {
          instagramToken: tok,
          instagramState: igState,
          // loader: false
        },
        () => {
          this.doSignInWithCustomToken(tok);
        }
      );
    }
  }

  UNSAFE_componentWillUpdate() {
    //check for state of instagram stuff and setup listener on that
    const {
      location: { state: { from } = {} },
      context = {},
      history,
    } = this.props;
    const { instagramState, instagramId, updateAppState } = context;

    if (!this.customTokenListener) {
      if (instagramId) {
        this.customTokenListener = firebase
          .database()
          .ref(
            `instagramuserinfobyinstagramid/${instagramId}/temp_linking_token`
          )
          .on('value', (snap) => {
            const tempLinkingCustomToken = snap.val(); // now
            if (tempLinkingCustomToken && tempLinkingCustomToken.length) {
              const {
                currentUser: { uid = '' },
              } = auth;
              const isInstagramChadAccount = uid.indexOf('instagram:') === 0;
              Promise.resolve(
                isInstagramChadAccount ? auth.currentUser.delete() : null
              )
                .then(() => {
                  updateAppState(
                    {
                      instagramState: null,
                      instagramId: null,
                      instagramToken: null,
                    },
                    () => {
                      if (instagramState === 'app') {
                        window.location = `https://${
                          UtilFX.getIsDevEnv() ? 'orun' : 'web'
                        }.favyogis.com/goto_native_app/?urleventid=igauth&token=${tempLinkingCustomToken}&igid=${instagramId}`;
                        // setTimeout(() => {
                        //   window.close();
                        // }, 500);
                      } else {
                        this.doSignInWithCustomToken(tempLinkingCustomToken);
                        history.push(from || '/?already=instagram-web');
                      }
                    }
                  );
                })
                .catch((err) => console.warn('error deleting user', { err }));
            }
          });
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.customTokenListener) {
      const { instagramId } = this.props.context || {};
      firebase
        .database()
        .ref(`instagramuserinfobyinstagramid/${instagramId}/temp_linking_token`)
        .off();
    }
  }

  verifyRecaptcha = () => {
    const { recaptchaClientOutput } = this.state;

    if (!recaptchaClientOutput) {
      this.setState({ loader: false });
      return (
        window &&
        window.alert(
          'Please tick the "I\'m not a robot." checkbox to continue.'
        )
      );
    }

    const requestOptions = {
      method: 'GET',
    };
    // https://encoded-ensign-158201.appspot.com
    const target = `https://encoded-ensign-158201.appspot.com/recaptcha/${recaptchaClientOutput}`; //FIX maybe use production google cloud service for reliability
    return fetch(target, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.info({ result });
        // this._mounted &&
        this.setState({
          // isLoaded: true,
          recaptchaResult: result,
        });
      })
      .catch((err) => {
        // this._mounted &&
        this.setState({
          // isLoaded: true,
          recaptchaResult: null,
          error: err,
        });
      });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const isDatadogSynthetic = window && window._DATADOG_SYNTHETICS_BROWSER;
    this.setState({ loader: true });
    return Promise.resolve(
      !isDatadogSynthetic &&
        !UtilFX.getIsDevEnv() &&
        !this.state.recaptchaResult &&
        this.verifyRecaptcha()
    )
      .then(() => {
        const { email, pword, recaptchaResult } = this.state;
        if (!isDatadogSynthetic && !UtilFX.getIsDevEnv()) {
          if (!recaptchaResult) {
            return console.warn('no result, probably not filled then.');
          }
          // return console.info({ recaptchaResult });
          if (recaptchaResult && !recaptchaResult.success) {
            return (
              window &&
              window.alert('We were unable to verify a valid human trace.')
            );
          }
        }

        this.doSignInWithEmailAndPassword(email, pword);
        ReactGA.event({
          category: 'Authentication',
          action: 'EmailAndPasswordLoginAttempt',
          label: 'SignIn',
          value: 0,
        });
        return true;
      })
      .catch((error) => {
        console.log('error', error);
        this.setState({ loader: false });
        return false;
      });
  };

  onSubmitCustomLinking = (e) => {
    //for intermediate IG signup steps
    e.preventDefault();
    this.setState({ loader: true });
    const { email, pword } = this.state;
    // auth.getUserByEmail(email)
    //   .then(user => {

    // })
    this.doSignInWithEmailAndPassword(email, pword);
  };

  handleChange = (e) => {
    this._mounted &&
      this.setState({
        [e.target.name]: e.target.value,
      });
  };

  kickoffInstagramAuth = () => {
    const dev_redirect_uri =
      'http://localhost:5000/encoded-ensign-158201/us-central1/oauthinstagram';
    const prod_redirect_uri =
      'https://us-central1-favyogis-prod.cloudfunctions.net/oauthinstagram';

    //see if userid is already present. if so thats the state to send. otherwise generate an anonymous account uid and send over with state
    const igClientId =
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD_INSTAGRAM_clientid
        : process.env.REACT_APP_DEV_INSTAGRAM_clientid;
    const redirectUri =
      process.env.NODE_ENV === 'production'
        ? prod_redirect_uri
        : dev_redirect_uri; //coming from this web app, so it is here? or rather to the server
    const targetUrl = `https://api.instagram.com/oauth/authorize/?client_id=${igClientId}&redirect_uri=${redirectUri}&response_type=code&state=web`;
    // console.log('kickoff IG Auth at this url-------->', targetUrl);
    window.location.href = targetUrl;
    // if (isMobile) {
    //   window.location.href = targetUrl;
    //   // console.warn("this is where i would reidrect NOT popup", { targetUrl });
    // } else {
    //   window.open(targetUrl);
    // }
  };

  kickoffAppleAuth = () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    this.setState({ loader: true });
    const handleOauthResult = (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
      // The signed-in user info.
      // var user = result.user;
      // console.warn("SUX: oauth google full result", result);

      // The signed-in user info.

      const { user } = result;
      // const accessToken = result.credential.accessToken;
      // const idToken = result.credential.idToken;

      UtilFX.logAnalytics('log in', { uid: user.uid });
      ReactGA.event({
        category: 'Authentication',
        action: 'AppleAuth Success',
        label: 'SignInApple',
        value: 1,
      });
      // < !--Event snippet for FavYogis signup conversion page
      // In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -- >
      window && window.gtag_report_conversion();
      window && window.fbq('track', 'CompleteRegistration');
    };

    (isMobile
      ? auth.signInWithRedirect(provider)
      : auth.signInWithPopup(provider)
    )
      .then(handleOauthResult)
      .catch((err) => {
        const {
          code,
          message,
          // email,
          // credential
        } = err;

        console.warn('found and error, update state accordingly', err);

        let messageFinal = message;

        messageFinal +=
          ' If the problem continues please logout and reset your email password. You can also email us anytime at help@favyogis.com';

        this._mounted &&
          this.setState(
            {
              error: messageFinal,
              errorCode: code,
              loader: false,
            },
            () => {
              ReactGA.event({
                category: 'Authentication',
                action: 'Failed KickoffAppleAuthAttempt',
                label: 'Failed_SignInApple',
                value: 0,
              });
            }
          );
        // }
      });
  };

  kickoffGoogleAuth = () => {
    // console.log("kickoff gogoel auth");
    this.setState({ loader: true });
    const provider = new firebase.auth.GoogleAuthProvider();
    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    const handleOauthResult = (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
      // The signed-in user info.
      // var user = result.user;
      // console.warn("SUX: oauth google full result", result);
      ReactGA.event({
        category: 'Authentication',
        action: 'GoogleAuth Success',
        label: 'SignInGoogle',
        value: 1,
      });
      const { user } = result;
      UtilFX.logAnalytics('log in', { uid: user.uid });
      // < !--Event snippet for FavYogis signup conversion page
      // In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -- >
      window && window.gtag_report_conversion();
      window && window.fbq('track', 'CompleteRegistration');
    };

    (isMobile
      ? auth.signInWithRedirect(provider)
      : auth.signInWithPopup(provider)
    )
      .then(handleOauthResult)
      .catch((err) => {
        const {
          code,
          message,
          // email,
          // credential
        } = err;

        console.warn('found and error, update state accordingly', err);

        let messageFinal = message;

        messageFinal +=
          ' If the problem continues please logout and reset your email password. You can also email us anytime at help@favyogis.com';

        this._mounted &&
          this.setState(
            {
              error: messageFinal,
              errorCode: code,
              loader: false,
            },
            () => {
              ReactGA.event({
                category: 'Authentication',
                action: 'Failed KickoffGoogleAuthAttempt',
                label: 'Failed_SignInGoogle',
                value: 0,
              });
            }
          );
        // }
      });
  };

  //calledwith empty password to generate
  doCreateAccountWithEmailAndPassword = () => {
    const { pword = '', email = '', pwordconfirm = '' } = this.state;
    if (!email.length || !pword.length || pword !== pwordconfirm) {
      let errMsg = 'Sorry, an error encountered.';

      if (pword !== pwordconfirm) errMsg = "Your password entries don't match";
      if (!email.length) errMsg = 'Enter an email address';
      if (!pword.length) errMsg = 'Enter a password';

      this._mounted &&
        this.setState({
          error: errMsg,
          errorCode: 'custom_ignore_temitope',
          loader: false,
        });

      return;
    }
    auth
      .createUserWithEmailAndPassword(email, pword)
      .then((credential) => {
        const { user } = credential;
        // normal user with no instagram_id yet. so set it. [setting userData is handled by auth checker] but may want to update it direct after mutating and updating
        // console.log("---> user from sing in", user)
        UtilFX.logAnalytics('sign up that logs you in authomatically', {
          uid: user.uid,
        });
        return database.ref(`users/${user.uid}/`).update({
          email: email,
        });
      })
      .then(() => {
        this._mounted &&
          this.setState(
            { error: null, errorCode: null, pword: '', loader: false },
            () => {
              // pushing happens with auth state change listerner history.push("/");
              ReactGA.event({
                category: 'Authentication',
                action: 'Standard Email and Password Signup Success',
                label: 'SignUpWithEmail',
                value: 1,
              });
              // < !--Event snippet for FavYogis signup conversion page
              // In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -- >
              window && window.gtag_report_conversion();
              window && window.fbq('track', 'CompleteRegistration');
            }
          );
      })
      .catch((err) => {
        const { code, message } = err;
        console.warn('found and error, update state accordingly', err);
        // auth/email-already-in-use
        // auth/weak-password
        // account already exists, show password box and message saying verify your account with password. show prompt for password
        // if (code === "auth/wrong-password") {
        //   // const randPw = (Math.random() * 1000000) + "-fav";
        //   //auth.signInWithEmailAndPassword(email, randPw)
        //   // auth/user-not-found
        //   // auth/wrong-password
        // }
        // else {
        this._mounted &&
          this.setState({
            error: message,
            errorCode: code,
            loader: false,
          });
        // }
      });
  };

  //calledwith empty password to generate
  doSignInWithEmailAndPassword = (email, password) => {
    const { context = {} } = this.props;
    const { pendingInstagramEmail, instagramId } = context || {};
    // console.log("about to signin with something and ocntext. test", email, password, this.props);
    // context.updateAppState({ poser: "yeeee" });
    // return;
    let pword = password || '';
    //if password is empty, straight shot: generate a rando and create the account and log them in, user uid to create a user for them with instagram info to prepopulate
    if (!pword.length && pendingInstagramEmail) {
      pword = Math.random() * 1000000 + '-favYogime';
      auth
        .createUserWithEmailAndPassword(email, pword)
        .then((userCred) => {
          const user = userCred.user;
          if (pendingInstagramEmail && instagramId) {
            // normal user with no instagram_id yet. so set it. [setting userData is handled by auth checker] but may want to update it direct after mutating and updating
            // database.ref(`users/${user.uid}/`)
            //   .update({ // creates baseline user properties
            //     instagram_id: instagramId,
            //     //username, profile_photo_, bio, fullname
            //   }).then(()=> {
            const InstagramByUserInfoRef = database.ref(
              `instagramuserinfobyinstagramid/${instagramId}`
            );
            database
              .ref(`users/${user.uid}/`)
              .once('value')
              .then((snap_u) => {
                InstagramByUserInfoRef.once('value').then((snap_igu) => {
                  const u = snap_u.val() || {};
                  const igu_user = (snap_igu.val() || {}).user || {};
                  const igUsername = igu_user.username;
                  const udata = {
                    instagram_id: igu_user.id,
                    //username: u.username || igu_user.username,
                    fullname: u.fullname || igu_user.full_name,
                    website: u.website || igu_user.website,
                    profile_photo_url:
                      u.profile_photo_url || igu_user.profile_picture,
                    bio: u.bio || igu_user.bio,
                    email: email,
                    creation_date: u.creation_date || Date.now(),
                  };

                  database
                    .ref(`users/${user.uid}/`)
                    .update(udata) // creates baseline user properties
                    .then(() => {
                      //already set with creation auth.currentUser.updateEmail(email)
                      //   .then(() => {
                      udata.uid = user.uid;
                      console.info('udata nd udataPost', udata); // , udataPost);
                      auth.currentUser.sendEmailVerification();

                      InstagramByUserInfoRef.update({
                        trigger_reset_token_date: Date.now(),
                      }).then(() => {
                        context.updateAppState({
                          pendingInstagramEmail: false,
                          userAuthenticated: udata,
                        });
                        if (!(u && u.username) && igUsername) {
                          DataFX.doSetUsernameAttempt({
                            uid: user.uid,
                            desiredUsername: igUsername,
                          });
                        }

                        //let us link the email cred account to the instagram one if avail

                        // });
                      });
                    });
                });
              });
          }

          //will handle user with state change perhaps rather than here

          //if here, success, so clear error msg
          this._mounted &&
            this.setState({ error: null, errorCode: null, loader: false });
        })
        .catch((err) => {
          const { code, message } = err;
          console.warn('found and error, update state accordingly', err);
          // auth/email-already-in-use
          // auth/weak-password
          // account already exists, show password box and message saying verify your account with password. show prompt for password
          if (code === 'auth/email-already-in-use') {
            this._mounted &&
              this.setState({
                showPwordField: true, // ForExistingUserAuthentication
                error:
                  'This email is already attached to an account. Enter your password to connect it.',
              });
            // const randPw = (Math.random() * 1000000) + "-fav";
            //auth.signInWithEmailAndPassword(email, randPw)
            // auth/user-not-found
            // auth/wrong-password
          } else {
            this._mounted &&
              this.setState({
                error: message,
                errorCode: code,
                loader: false,
              });
          }
        });
    } else {
      auth
        .signInWithEmailAndPassword(email, pword)
        .then((credential) => {
          const { user } = credential;
          if (pendingInstagramEmail && instagramId) {
            // normal user with no instagram_id yet. so set it. [setting userData is handled by auth checker] but may want to update it direct after mutating and updating
            // console.log("---> user from sing in", user)
            return database
              .ref(`users/${user.uid}/`)
              .update({
                instagram_id: instagramId,
              })
              .then(() => {
                const InstagramByUserInfoRef = database.ref(
                  `instagramuserinfobyinstagramid/${instagramId}`
                );
                return InstagramByUserInfoRef.update({
                  trigger_reset_token_date: Date.now(),
                }).then(() => {
                  this._mounted &&
                    this.setState(
                      {
                        error: null,
                        errorCode: null,
                        pword: '',
                        pristine: false,
                      },
                      () => {}
                    );

                  context.updateAppState({
                    pendingInstagramEmail: false,
                  });
                });
              });
          }
          //will handle user with state change perhaps rather than here
          //if here, success, so clear error msg
          //const { history } = this.props;
          ReactGA.event({
            category: 'Authentication',
            action: 'Standard Email and Password Login Success',
            label: 'LoginWithEmail',
            value: 0,
          });
          UtilFX.logAnalytics('log in', { uid: user.uid });
        })
        .catch((err) => {
          const { code, message } = err;
          console.warn('found and error, update state accordingly', err);
          // auth/email-already-in-use
          // auth/weak-password
          // account already exists, show password box and message saying verify your account with password. show prompt for password
          // if (code === "auth/wrong-password") {
          //   // const randPw = (Math.random() * 1000000) + "-fav";
          //   //auth.signInWithEmailAndPassword(email, randPw)
          //   // auth/user-not-found
          //   // auth/wrong-password
          // }
          // else {

          let messageFinal = message;
          if (pendingInstagramEmail && instagramId) {
            messageFinal +=
              ' If the problem continues please logout and reset your email password. You can also email us anytime at help@favyogis.com or by using the help button in the main menu.';
          }

          this._mounted &&
            this.setState({
              error: messageFinal,
              errorCode: code,
              loader: false,
            });
          // }
        });
    }
  };

  doSignInWithCustomToken = (token) => {
    auth
      .signInWithCustomToken(token)
      .then((user) => {
        // will handle user with state change perhaps rather than here
        // if here, success, so clear error msg
        console.info('user from sign in wth custom token', user);
        this._mounted &&
          this.setState({ error: null, errorCode: null, loader: false }, () => {
            //now time to close this window since you have a token it was redirected here and there
            //should be another screen that auto logs in as well do to auth state change listerner
            //may be an issue when adding teachers and that info is only in..(local storage perhaps)
            // if (!isMobile) {
            //  // window.close();
            // }
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        this.setState({ loader: false });
        // var errorMessage = error.message;
        if (errorCode === 'auth/invalid-custom-token') {
          console.warn('The token you provided is not valid.');
        } else {
          console.error(error);
        }
      });
  };

  // handleInstagramLogin = (id) => {
  //   const { context } = this.props;
  //   database.ref(`users`)
  //     .orderByChild("instagram_id")
  //     .equalTo(id)
  //     .once('value').then((snap) => {
  //       const u = snap.val();
  //       const uid = Object.keys(u || {})[0];
  //       // no existing account for user, so prompt for email to create or link one
  //       if (!uid) {
  //         console.warn("//never been linked before. prompt for email and potentially password to create new or validate old");
  //         context && context.updateAppState({ pendingInstagramEmail: true });
  //       } else {
  //         // are they already linked to a user?
  //         const udata = u[uid];
  //         udata.uid = uid;
  //         console.log("FOUND User data in auth ui", udata);
  //         // if so we need to link accounts? or is that done at auth?
  //         context && context.updateAppState({
  //           userData: udata,
  //           pendingInstagramEmail: false,
  //         });
  //       }
  //     })
  // }

  doForgotPassword = () => {
    // loaderHandler.showLoader("Sending Email");
    const { email } = this.state;
    auth.sendPasswordResetEmail(email).then(
      () => {
        // Email sent.
        // loaderHandler.hideLoader();
        this._mounted &&
          this.setState({
            error: null,
            loader: false,
            message:
              'Success!\n Check your inbox for instructions regarding your password.',
          });
        // this._showModal();
        // analytics.logEvent("forgotpwd_success", {});
        //console.log("suuccessfully sent forgot pw email")
      },
      (error) => {
        // loaderHandler.hideLoader();
        //show modal
        this._mounted &&
          this.setState({
            error: error.message,
            errorCode: error.code,
            loader: false,
          });
        // this._showModal();
        // Analytics.logEvent("forgotpwd_fail", {});
        // An error happened.

        // console.log("error sending forgot pw", error)
      }
    );

    ReactGA.event({
      category: 'Authentication',
      action: 'ForgotPassword Attempt',
      label: 'ForgotPassword',
      value: 0,
    });
  };

  handleReCaptchaChange = (val) => {
    this.setState({ recaptchaClientOutput: val });
  };

  PendingInstagramUI = (props) => {
    const { showPwordField } = this.state;
    return (
      <Grid container justify="center">
        <Grid item xs={10} style={{ marginTop: 30 }}>
          {/* <h1>Sign In</h1> */}
          {/* <div style={{
          marginBottom: 50,
        }}>
          <a onClick={() => {
            console.log("//update state for show email portion");
          }}><i>Sign in with email.</i></a>
        </div> */}
          <h1>Nearly there!</h1>
          <Typography style={{ color: '#fff', marginBottom: 10 }}>
            We just need to confirm your email and you'll be simplifying your
            life in no time!
          </Typography>
          <form onSubmit={this.onSubmitCustomLinking}>
            <hr />
            <div style={GlobalStyles.textInputContainer}>
              <MdEmail
                style={{ color: '#fff', paddingRight: 5, fontSize: '2em' }}
              />
              <input
                id="formEmailText"
                placeholder="Email"
                style={GlobalStyles.textInputDefault}
                type="email"
                name="email"
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>

            {!showPwordField ? null : (
              <div style={GlobalStyles.textInputContainer}>
                <MdLock
                  style={{ color: '#fff', paddingRight: 5, fontSize: '2em' }}
                />
                <input
                  placeholder="Password"
                  style={GlobalStyles.textInputDefault}
                  type="password"
                  name="pword"
                  autoComplete="current-password"
                  value={this.state.pword}
                  onChange={this.handleChange}
                />
              </div>
            )}
            <div>
              {this.state.error && (
                <AlertBox>
                  <strong>Error: </strong>
                  {this.state.error}
                </AlertBox>
              )}
              <Button type="submit">Save details</Button>
            </div>
            <br />
            <Typography style={{ color: '#fff' }}>
              <span>
                <b>Why?</b>
              </span>
            </Typography>
            <Typography style={{ color: '#fff' }}>
              <small>
                We need your email to verify your FavYogis account. It's how
                we'll let you know about updates and notifications. You can
                always manage your notifications.
              </small>
            </Typography>
          </form>
        </Grid>
      </Grid>
    );
  };

  newLoginUI = (props) => {
    return (
      <React.Fragment>
        <Typography
          style={{
            ...GlobalStyles.titleBold,
            marginTop: '50px',
            color: '#333333',
            fontSize: '20px',
            fontWeight: 600,
          }}
        >
          Sign in to FavYogis
        </Typography>
        {this.state.error ? (
          <AlertBox>
            <strong>Error: </strong>
            {this.state.error}
          </AlertBox>
        ) : null}
        <div
          className="auth-social-cta auth-social-cta__google"
          onClick={this.kickoffGoogleAuth}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
            marginBottom: 15,
          }}
        >
          <img alt="googleLogo" src={googleLogo} />
          <Typography style={{ color: '#828282', ...GlobalStyles.bodyMedium }}>
            Sign in with Google
          </Typography>
          <Typography />
        </div>
        <div
          className="auth-social-cta auth-social-cta__apple"
          onClick={this.kickoffAppleAuth}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}
        >
          <img alt="appleLogo" src={appleLogo} />
          <Typography style={{ ...GlobalStyles.bodyMedium, color: '#828282' }}>
            Sign in with Apple
          </Typography>
          <Typography />
        </div>

        <div className="lineDivide" style={{ marginTop: 15 }}>
          <h3>Or</h3>
        </div>
        <form onSubmit={this.onSubmit}>
          <TextFieldComponent
            onChange={this.handleChange}
            placeholder="Email"
            id="formEmailText"
            type="email"
            name="email"
            autoComplete="email"
            value={this.state.email}
            startAdornment={
              <InputAdornment
                position="start"
                component="div"
                style={{}}
                disablePointerEvents
              >
                <img
                  style={{ borderRight: '1px solid #E0E0E0', padding: '7px' }}
                  alt="envelopeIcon"
                  src={envelopeIcon}
                />
              </InputAdornment>
            }
            inputStyle={{
              ...GlobalStyles.bodyMedium,
              border: '1px solid #E0E0E0',
              padding: '5px',
              borderRadius: '20px',
              color: '#828282',
              marginBottom: 15,
            }}
          />
          <TextFieldComponent
            onChange={this.handleChange}
            placeholder="Password"
            type={this.state.hidePassword ? 'text' : 'password'}
            name="pword"
            autoComplete="current-password"
            value={this.state.pword}
            startAdornment={
              <InputAdornment
                position="start"
                component="div"
                style={{}}
                disablePointerEvents
              >
                <img
                  style={{ borderRight: '1px solid #E0E0E0', padding: '10px' }}
                  alt="keyIcon"
                  src={keyIcon}
                />
              </InputAdornment>
            }
            inputStyle={{
              ...GlobalStyles.bodyMedium,
              border: '1px solid #E0E0E0',
              padding: '5px',
              borderRadius: '20px',
              color: '#828282',
              marginBottom: 15,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{
                    padding: '15px',
                    color: this.state.pword ? Colors.pri_color : '#E0E0E0',
                  }}
                  edge="end"
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                >
                  {this.state.hidePassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
              borderRadius: '20px',
            }}
          >
            <ReCAPTCHA
              sitekey="6Le1KIsUAAAAAPrXyARc9aCe73MLxLF7qU7qmPdM"
              onChange={this.handleReCaptchaChange}
            />
          </div>
          <ButtonComponent
            buttonStyle={{ padding: '10px' }}
            type="submit"
            id="login-submit"
            value={'SIGN IN'}
          />
        </form>
        {this.state.error && (
          <AlertBox>
            <strong>Error: </strong>
            {this.state.error}
          </AlertBox>
        )}
        <div style={{ marginTop: '15px', marginBottom: '15px' }}>
          <Typography style={{ ...GlobalStyles.bodyMedium, color: '#828282' }}>
            {' '}
            Forgot password?
            <Link
              to="/forgot"
              style={{
                ...GlobalStyles.bodyMedium,
                color: '#1FA3FF',
                textDecoration: 'none',
              }}
            >
              {' '}
              Reset password
            </Link>
          </Typography>
          <Typography style={{ color: '#828282', ...GlobalStyles.bodyMedium }}>
            {' '}
            Don't have an account?
            <Link
              to="/signup"
              style={{
                ...GlobalStyles.bodyMedium,
                color: '#1FA3FF',
                textDecoration: 'none',
              }}
            >
              {' '}
              Sign up
            </Link>
          </Typography>
        </div>
      </React.Fragment>
    );
  };

  newSignupUI = (props) => {
    return (
      <React.Fragment>
        <Typography
          style={{
            ...GlobalStyles.titleBold,
            marginTop: '50px',
            color: '#333333',
            fontSize: '20px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          Sign up to FavYogis
        </Typography>
        {this.state.error && (
          <AlertBox>
            <strong>Error: </strong>
            {this.state.error}
          </AlertBox>
        )}
        <div
          className="auth-social-cta auth-social-cta__google"
          onClick={this.kickoffGoogleAuth}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
            marginBottom: 15,
          }}
        >
          <img alt="googleLogo" src={googleLogo} />
          <Typography style={{ ...GlobalStyles.bodyMedium, color: '#828282' }}>
            Sign up with Google
          </Typography>
          <Typography />
        </div>

        <div
          className="auth-social-cta auth-social-cta__apple"
          onClick={this.kickoffAppleAuth}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}
        >
          <img alt="appleLogo" src={appleLogo} />
          <Typography style={{ ...GlobalStyles.bodyMedium, color: '#828282' }}>
            Sign up with Apple
          </Typography>
          <Typography />
        </div>

        <div className="lineDivide">
          <h3>Or</h3>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await Promise.resolve(
              !UtilFX.getIsDevEnv() &&
                !this.state.recaptchaResult &&
                this.verifyRecaptcha()
            );
            const { recaptchaResult } = this.state;
            if (!UtilFX.getIsDevEnv()) {
              if (!recaptchaResult) {
                return console.warn('no result, probably not filled then.');
              }
              // return console.info({ recaptchaResult });
              if (recaptchaResult && !recaptchaResult.success) {
                return (
                  window &&
                  window.alert('We were unable to verify a valid human trace.')
                );
              }
            }
            this.doCreateAccountWithEmailAndPassword();
            ReactGA.event({
              category: 'Authentication',
              action: 'doCreateAccountWithEmailAndPassword',
              label: 'SignUp',
              value: 5,
            });
          }}
        >
          <TextFieldComponent
            onChange={this.handleChange}
            placeholder="Email"
            id="formEmailText"
            type="email"
            name="email"
            autoComplete="email"
            value={this.state.email}
            startAdornment={
              <InputAdornment
                position="start"
                component="div"
                style={{}}
                disablePointerEvents
              >
                <img
                  style={{ borderRight: '1px solid #E0E0E0', padding: '7px' }}
                  alt="envelopeIcon"
                  src={envelopeIcon}
                />
              </InputAdornment>
            }
            inputStyle={{
              ...GlobalStyles.bodyMedium,
              border: '1px solid #E0E0E0',
              padding: '5px',
              borderRadius: '20px',
              color: '#828282',
              marginBottom: 15,
            }}
          />
          <TextFieldComponent
            onChange={this.handleChange}
            placeholder="Password"
            type={this.state.hidePassword ? 'text' : 'password'}
            name="pword"
            autoComplete="current-password"
            value={this.state.pword}
            startAdornment={
              <InputAdornment
                position="start"
                component="div"
                style={{}}
                disablePointerEvents
              >
                <img
                  style={{ borderRight: '1px solid #E0E0E0', padding: '10px' }}
                  alt="keyIcon"
                  src={keyIcon}
                />
              </InputAdornment>
            }
            inputStyle={{
              ...GlobalStyles.bodyMedium,
              border: '1px solid #E0E0E0',
              padding: '5px',
              borderRadius: '20px',
              color: '#828282',
              marginBottom: 15,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{
                    padding: '15px',
                    color: this.state.pword ? Colors.pri_color : '#E0E0E0',
                  }}
                  edge="end"
                  onClick={() =>
                    this.setState({ hidePassword: !this.state.hidePassword })
                  }
                >
                  {this.state.hidePassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <TextFieldComponent
            onChange={this.handleChange}
            placeholder="Re-enter Password"
            type={this.state.hidePassword2 ? 'text' : 'password'}
            name="pwordconfirm"
            autoComplete="repeat-new-password"
            value={this.state.pwordconfirm}
            startAdornment={
              <InputAdornment
                position="start"
                component="div"
                style={{}}
                disablePointerEvents
              >
                <img
                  style={{ borderRight: '1px solid #E0E0E0', padding: '10px' }}
                  alt="keyIcon"
                  src={keyIcon}
                />
              </InputAdornment>
            }
            inputStyle={{
              ...GlobalStyles.bodyMedium,
              border: '1px solid #E0E0E0',
              padding: '5px',
              borderRadius: '20px',
              color: '#828282',
              marginBottom: 15,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{
                    padding: '15px',
                    color: this.state.pwordconfirm
                      ? Colors.pri_color
                      : '#E0E0E0',
                  }}
                  edge="end"
                  onClick={() =>
                    this.setState({ hidePassword2: !this.state.hidePassword2 })
                  }
                >
                  {this.state.hidePassword2 ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '20px',
              marginBottom: '15px',
            }}
          >
            <ReCAPTCHA
              sitekey="6Le1KIsUAAAAAPrXyARc9aCe73MLxLF7qU7qmPdM"
              onChange={this.handleReCaptchaChange}
            />
          </div>

          <ButtonComponent
            buttonStyle={{ padding: '10px' }}
            type="submit"
            value={'SIGN UP'}
          />
        </form>
        {this.state.error && (
          <AlertBox>
            <strong>Error: </strong>
            {this.state.error}
          </AlertBox>
        )}
        <div style={{ marginTop: '15px', marginBottom: '15px' }}>
          <Typography style={{ ...GlobalStyles.bodyMedium, color: '#828282' }}>
            Already have an account?{' '}
            <Link
              id="login-link"
              to="/login"
              style={{
                ...GlobalStyles.bodyMedium,
                color: '#1FA3FF',
                textDecoration: 'none',
              }}
            >
              Sign in
            </Link>
          </Typography>
        </div>
      </React.Fragment>
    );
  };

  newForgotUI = (props) => (
    <React.Fragment>
      <Typography
        style={{
          ...GlobalStyles.titleBold,
          marginTop: '50px',
          marginBottom: 25,
          color: '#333333',
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        Reset your password
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return Promise.resolve(
            !UtilFX.getIsDevEnv() &&
              !this.state.recaptchaResult &&
              this.verifyRecaptcha()
          ).then(() => {
            const { recaptchaResult } = this.state;
            if (!UtilFX.getIsDevEnv()) {
              if (!recaptchaResult) {
                return console.warn('no result, probably not filled then.');
              }
              // return console.info({ recaptchaResult });
              if (recaptchaResult && !recaptchaResult.success) {
                return (
                  window &&
                  window.alert('We were unable to verify a valid human trace.')
                );
              }
            }
            this.doForgotPassword();
            ReactGA.event({
              category: 'Authentication',
              action: 'doForgotPassword',
              label: 'ForgotPassword',
              value: 0,
            });
          });
        }}
      >
        <TextFieldComponent
          onChange={this.handleChange}
          placeholder="Email"
          id="formEmailText"
          type="email"
          name="email"
          autoComplete="email"
          value={this.state.email}
          startAdornment={
            <InputAdornment
              position="start"
              component="div"
              style={{}}
              disablePointerEvents
            >
              <img
                style={{ borderRight: '1px solid #E0E0E0', padding: '7px' }}
                alt="envelopeIcon"
                src={envelopeIcon}
              />
            </InputAdornment>
          }
          inputStyle={{
            ...GlobalStyles.bodyMedium,
            border: '1px solid #E0E0E0',
            padding: '5px',
            borderRadius: '20px',
            color: '#828282',
            marginBottom: 15,
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '20px',
          }}
        >
          <ReCAPTCHA
            sitekey="6Le1KIsUAAAAAPrXyARc9aCe73MLxLF7qU7qmPdM"
            onChange={this.handleReCaptchaChange}
          />
        </div>
        <div>
          {this.state.error && (
            <AlertBox>
              <strong>Error: </strong>
              {this.state.error}
            </AlertBox>
          )}
          {this.state.message && (
            <AlertBox bgColor="#ECFFF4">
              <img alt="successMark" src={successMark} />
              <div>{this.state.message}</div>
            </AlertBox>
          )}
          <ButtonComponent
            buttonStyle={{ marginBottom: 24, marginTop: 20, padding: '10px' }}
            type="submit"
            value={'SUBMIT'}
          />
        </div>
      </form>
      <div>
        <Typography style={{ ...GlobalStyles.bodyMedium, color: '#828282' }}>
          Done resetting password?{' '}
          <Link
            id="login-link"
            to="/login"
            style={{
              ...GlobalStyles.bodyMedium,
              color: '#1FA3FF',
              textDecoration: 'none',
            }}
          >
            Try signing in
          </Link>
        </Typography>
      </div>
    </React.Fragment>
  );

  // Loader = () => {
  //   return (
  //     <div style={{ flex: 1, backgroundColor: Colors.pri_color }}>
  //       <div style={{ justifyContent: 'center', alignItems: 'center' }}>
  //         <Loading>
  //           ...
  //         </Loading>
  //       </div>
  //     </div>
  //   );
  // }

  GifLoader = () => {
    return (
      <div>
        <GifLoader />
      </div>
    );
  };

  render() {
    const {
      context: { pristine },
    } = this.props;
    if (this.state.loader || pristine) {
      return this.GifLoader();
    }
    return (
      <GlobalContext.Consumer>
        {(ctx) => {
          const { match: { path } = {}, context = {} } = this.props;
          const { pendingInstagramEmail, userAuthenticated } = context;
          // console.log("auth ui props and state", this.props, this.state)
          // console.log("auth ui ===== ctx", ctx)
          // console.log("auth ui -==== props.context", context)
          // console.log("login render determine stuff fo rdi", { stateReturned: this.instagramStateReturned, pendingInstagramEmail, instagramToken, instagramState });
          let ChosenUI = this.GifLoader;

          if (auth.currentUser && pendingInstagramEmail) {
            ChosenUI = this.PendingInstagramUI;
          } else if (path && path.toLowerCase().startsWith('/signup')) {
            ChosenUI = this.newSignupUI;
          } else if (path && path.toLowerCase().startsWith('/forgot')) {
            ChosenUI = this.newForgotUI;
          } else if (path && path.toLowerCase().startsWith('/login')) {
            ChosenUI = this.newLoginUI;
          }

          if (this.props.chosenUI) {
            if (this.props.chosenUI === 'signup') {
              ChosenUI = this.newSignupUI;
            }
          }

          if (
            auth.currentUser &&
            userAuthenticated &&
            userAuthenticated.uid &&
            !pendingInstagramEmail
          ) {
            //can add logic to einclude anytime a current user is witing here for good reason.
            //may be hanging chad user or not but just catch those scenarios to avoid double clickigna  login ation whiel in themiddle o r f waitng
            //generally no auth users belog here, but sometimes they can be in themiddlf begtting gyuser accotn setup (via email or mid redirect/check or first time setup)

            // console.warn("//can determine if any pending urls are here and substitude them if htey are not login");
            //is a full user now and has no pending issues, time to get out of here
            //at this point they are done with the auth/login/signup page and have no biz being here. they are authenticated fully
            return this.GifLoader();
          }

          return (
            <Grid
              container
              justify="center"
              style={{
                backgroundColor: '#fff',
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Grid item xs={10} style={{ maxWidth: 336 }}>
                <ChosenUI {...this.props} />
              </Grid>
            </Grid>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.FirebaseAuthStateListenerCallToUnsubscribe = null;

    //this.unlisten;
    this.state = {
      openDrawer: false,
      email: '',
      pword: '',
      error: null,
      user: null,
      pristine: true,
      pendingGroupChatInvitesCount: 0,
      pendingGroupChatMembersCount: 0,
      notificationAlert: true,

      refreshUserAuthenticated: () => {
        // console.log("ive been called to rfresh user Auth");

        this.setUserData();
      },
      updateAuthenticatedUser: (user) => {
        this.setState({
          userAuthenticated: user,
          pendingInstagramEmail: !user,
          pristine: false,
        });
      },
      updateAppState: (newStateBits, cb) => {
        // console.log("====>Updateing Context App state", newStateBits)
        this.setState(
          {
            ...this.state,
            ...newStateBits,
          },
          () => {
            if (typeof cb === 'function') {
              cb(); //need anything to passback?
            }
          }
        );
      },
    };
  }

  componentDidMount() {
    this._mounted = true;
    setTimeout(() => {
      document.getElementById('App').style.opacity = 1;
    }, 100);
    ReactGA &&
      ReactGA.initialize('UA-123191964-2', { testMode: UtilFX.getIsDevEnv() });
    ReactGA &&
      window &&
      ReactGA.pageview(window.location.pathname + window.location.search);

    // console.log("props on did mount", this.props)
    requestFirebaseNotificationPermission();
    this.FirebaseAuthStateListenerCallToUnsubscribe = auth.onAuthStateChanged(
      (user) => {
        if (user) {
          const u = user;
          //got base auth user and an id, now fetch user data from db to set the state up.
          this.setState({ user });
          UtilFX.getIsDevEnv();
          // && console.log("found a user here in login auth", user);
          const { uid = '' } = u;
          if (uid.startsWith('instagram:')) {
            console.info('// we have an instagram user. ', uid);
            const nakedId = uid.replace('instagram:', '');
            this.setState(
              {
                instagramId: nakedId,
              },
              () => {
                this.handleInstagramLogin(nakedId);
              }
            );
          } else {
            Promise.resolve(this.setUserData(u.uid)).then(() => {
              const p = this.props.location.pathname.toLowerCase();
              const { state: { from } = {} } = this.props.location || {};
              if (p === '/login' || p === '/signup' || p === '/forgot') {
                this.props.history.push(from || '/?already');
              }
            });
          }
        } else {
          this._mounted &&
            this.setState({
              user: null,
              userAuthenticated: null,
              email: '',
              pword: '',
              pristine: false,
            });
        }
      }
    );
  }

  componentDidUpdate() {
    if(this.state.openDrawer && this.state.notificationAlert){
      this.getUpdatedNotifications()
 
    }
  }
  componentWillUnmount() {
    // const { userAuthenticated } = this.state;

    this._mounted = false;

    this.unlisten && this.unlisten();
    this.FirebaseAuthStateListenerCallToUnsubscribe &&
      this.FirebaseAuthStateListenerCallToUnsubscribe();

    // if (userAuthenticated && userAuthenticated.uid) {
    //database.ref(`users/${userAuthenticated.uid}`)
    // .off();
    // }
  }

  setUserData = (uid) => {
    const { user } = this.state;

    const userId = uid || user.uid;

    if (!userId) {
      this.setState({ pristine: false });
      return Promise.resolve(null);
    }

    return database.ref(`users/${userId}`).once('value', async (snap) => {
      const u = snap.val();
      if (u) {
        u.uid = userId;
        try {
          await this.updateWebappLoadStats({ fyUser: u });
          window.analytics &&
            window.analytics.identify(userId, {
              name: u.fullname,
              email: u.email,
              avatar: u.profile_photo_url,
              username: u.username,
              description: u.bio,
              website: u.website,
            });
          window.Rollbar &&
            window.Rollbar.configure({
              payload: {
                person: {
                  id: userId, // required
                  username: u.username,
                  email: u.email,
                },
              },
            });
          window.FS &&
            window.FS.identify(userId, {
              displayName: u.fullname,
              email: u.email,
              username_str: u.username,
            });
        } catch (err) {
          console.warn('Error trying ot updateWebappLoadState', err);
        }
      } else {
        //console.log("//no FY user but tthere is a userAuthenticated. this there id");
        //no user but tthere is a uaser
        if (
          user &&
          user.providerData &&
          user.providerData[0] &&
          user.providerData[0].providerId === 'google.com'
        ) {
          // its a hanging-chad google account. make her a user or report an error.
          // const pkg = {
          //   displayName: user.displayName,
          //   email: user.email,
          //   photoURL: user.photoURL,
          //   uid: user.uid,
          // };
          // console.log("//turn a hanging-chad google user into a FY user", pkg);
          this.handleGoogleLogin();
        }
      }
      UtilFX.getIsDevEnv();
      // && console.log("retrieved db user", u)
      this._mounted &&
        this.setState({
          userAuthenticated: u,
          pristine: false,
        });

      ReactGA && ReactGA.set({ userId: userId });
    });
  };

  checkAndSetInboxCount = (user) => {
    UtilFX.getInboxUnreadCount(user.uid).then((unread_count) => {
      if (unread_count !== this.state.unread_count) {
        this.setState({
          inbox_unread_count: unread_count,
        });
      }
    });
  };

  checkPendingGroupRequestCount = async (user) => {
    if(user?.uid){
      const convos = [];
      const pendingMembers = [];
      let query = await firestore.collection(ConvoMetadata);
      query = query.where('teacher_id', '==', user.uid);
      const response = await query.get();
      response.forEach((doc) => {
        convos.push(doc.data());
      });
      Promise.all(
        convos.map(async (convo) => {
          let query = await firestore.collection('ConvoMembers');
          query = query.where('convo_id', '==', convo.convo_id);
          query = query.where('status', '==', 'pending');
          const res = await query.get();
          res.forEach((doc) => {
            pendingMembers.push({ documentId: doc.id, ...doc.data(), ...convo });
          });
        })
      ).then(() => {
        this.setState({
          pendingGroupChatMembers: pendingMembers,
          pendingGroupChatMembersCount: pendingMembers.length,
        });
      });
    }
  };

  getGroupChatInvites = async (user) => {
    if(user?.uid){
      const invites = [];
      const convos = [];
      let query = await firestore.collection('ConvoMembers');
      query = query.where('uid', '==', user.uid);
      query = query.where('status', '==', 'invited');
      const response = await query.get();
      response.forEach((doc) => {
        invites.push({ documentId: doc.id, ...doc.data() });
      });
      Promise.all(
        invites.map(async (convo) => {
          let query = await firestore.collection(ConvoMetadata);
          query = query.where('convo_id', '==', convo.convo_id);
          const res = await query.get();
          if (!res.empty) {
            const result = res.docs[0].data();
            convos.push({ ...result, ...convo });
          }
        })
      ).then(() => {
        this.setState({
          pendingGroupChatInvites: convos,
          pendingGroupChatInvitesCount: convos.length,
          notificationAlert: false
        });
      });
    }
  };

  getUpdatedNotifications = () => {
    this.checkPendingGroupRequestCount(this.state.user);
    this.getGroupChatInvites(this.state.user)
  }
  updateWebappLoadStats = ({ fyUser }) => {
    this.checkAndSetInboxCount(fyUser);
    if (fyUser?.uid) {
      this.checkPendingGroupRequestCount(fyUser);
      this.getGroupChatInvites(fyUser);
    }
    let appweb_load_count = (fyUser && fyUser.appweb_load_count) || 0;
    appweb_load_count++;

    const now = Date.now();

    const updateData = {};
    updateData.appweb_load_count = appweb_load_count;
    updateData.appweb_last_load_date_local = now;
    // this.setState({ appweb_last_load_date_local: now });

    updateData.appweb_last_load_date = firebase.database.ServerValue.TIMESTAMP;

    const time_since_last_load =
      now - ((fyUser && fyUser.appweb_last_load_date_local) || now);

    // analytics.logEvent("appweb_open");

    updateData.appweb_time_since_last_load = time_since_last_load;
    // updateData["appweb_platform"] = Platform.OS;
    updateData.appweb_version = UtilFX.getVersion();

    const user = this.state.user || {};
    if (fyUser.email !== user.email) {
      updateData.email = user.email;
    }

    // console.log("update Data we about to save", updateData);
    database
      .ref(`users/${fyUser.uid}`)
      .update(updateData)
      .catch((err) => {
        console.warn('error updating appwebstats', err);
      });
  };

  // // Password Change
  // export const doPasswordUpdate = (password) =>
  //   auth.currentUser.updatePassword(password);

  doLogout = () => {
    const { history } = this.props || {};
    const { updateAppState } = this.state;
    history.push('/'); //send them back home
    UtilFX.doLogout();
    updateAppState({ userAuthenticated: null, pristine: false });
    this.handleClose();
  };

  handleInstagramLogin = (id) => {
    database
      .ref(`instagramuserinfobyinstagramid/${id}`)
      .once('value', (snap_igInfo) => {
        const igInfo = snap_igInfo.val() || {};
        database
          .ref('users')
          .orderByChild('instagram_id')
          .equalTo(id)
          .once('value')
          .then((snap) => {
            const u = snap.val() || {};
            const uid = Object.keys(u)[0];
            // no existing account for user, so prompt for email to create or link one
            if (!u || !uid) {
              console.warn(
                '//-- never been linked before. prompt for email and potentially password to create new or validate old'
              );
              this.setState({
                pendingInstagramEmail: true,
              });
            } else {
              // are they already linked to a user?
              const udata = u[uid];
              // console.log("FOUND User data for an ig auth attempt", udata);
              udata.uid = uid;

              // if so we need to link accounts? or is that done at auth?

              //need to trigger new token creation then listen for it to do a customToken sign in. then stop listening
              this.setState(
                {
                  // userAuthenticated: udata,
                  pendingInstagramEmail: !udata,
                },
                () => {
                  UtilFX.getIsDevEnv();
                  // && console.log("just set state fo user data-->", this.state, this.props);
                  console.warn({ igInfo });

                  const { temp_linking_token } = igInfo;
                  if (temp_linking_token) {
                    auth.signInWithCustomToken(temp_linking_token);
                  }
                  // history.push("/login");
                }
              );
            }
          });
      });
  };

  handleGoogleLogin = () => {
    // const { context } = this.props;
    // const { pendingInstagramEmail, instagramId } = context;
    const { user: googleProviderUser, updateAppState } = this.state;
    // console.log("//turn a hanigng-chad google user into a FY user", googleProviderUser, this.state);
    //if password is empty, straight shot: generate a rando and create the account and log them in, user uid to create a user for them with instagram info to prepopulate

    // normal user with no instagram_id yet. so set it. [setting userData is handled by auth checker] but may want to update it direct after mutating and updating
    database
      .ref(`users/${googleProviderUser.uid}/`)
      .once('value')
      .then((snap_u) => {
        const u = snap_u.val() || {};
        //const igu_user = (snap_igu.val() || {}).user || {};
        //const igUsername = igu_user.username;
        const udata = {
          fullname: u.fullname || googleProviderUser.displayName,
          profile_photo_url: u.profile_photo_url || googleProviderUser.photoURL,
          email: googleProviderUser.email,
          creation_date: u.creation_date || Date.now(),
          uid: googleProviderUser.uid,
        };

        database
          .ref(`users/${googleProviderUser.uid}/`) //possibly the first time being created. hence the call for updatewebaploadstate  below
          .update(udata) // creates baseline user properties
          .then(async () => {
            udata.uid = googleProviderUser.uid;
            try {
              await this.updateWebappLoadStats({ fyUser: u });
            } catch (err) {
              console.warn('Error trying ot updateWebappLoadState', err);
            }
            this.updateWebappLoadStats({ fyUser: udata });
            console.info('udata nd udataPost', udata); // , udataPost);
            updateAppState({
              // pendingInstagramEmail: false,
              userAuthenticated: udata,
              pristine: false,
            });
            // console.log("attempting to set username?", u, udata);
            if (!(u && u.username) && udata.fullname) {
              const firstName = udata.fullname.split(' ')[0];
              // console.log("YES I am attempting to set username:", firstName);
              DataFX.doSetUsernameAttempt({
                uid: googleProviderUser.uid,
                desiredUsername: firstName,
              });
            }
          });
        //will handle user with state change perhaps rather than here
        //if here, success, so clear error msg
        this.setState({ error: null, errorCode: null });
      })
      .catch((err) => {
        const { code, message } = err;
        console.warn('found and error, update state accordingly', err);
        // auth/email-already-in-use
        // auth/weak-password
        this.setState({
          error: message,
          errorCode: code,
        });
      });
  };

  FYDrawerX = (props) => {
    const {
      userAuthenticated,
      openDrawer,
      user,
      pendingGroupChatMembersCount,
      pendingGroupChatMembers,
      pendingGroupChatInvites,
      pendingGroupChatInvitesCount,
    } = this.state;
    const { history, theme } = this.props;
    const appUrl = 'favyogis://';
    const viewingUserHasInstalledApp =
      userAuthenticated && userAuthenticated.app_version;
    const supportedMobilePhoneDetected =
      navigator && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      // check if the default profile photo url is the same with user profile photo url  
      const onSignUpProfileUrl = DefaultProfilePhotoUrl === userAuthenticated?.profile_photo_url;

    return (
      <Drawer
        // width={300}
        open={openDrawer}
        variant="temporary"
        // classes={{ docked: true, }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={this.handleToggle}
      >
        <div
          style={{
            color: Colors.pri_color,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              flex: 0,
              position: 'relative',
              padding: 10,
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            <div
              id="hamburger-drawer-close-btn"
              // className="touchable-element"
              style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                left: 0,
                top: 0,
                fontSize: '0.75em',
                // border: "1px white solid",
                padding: 5,
                zIndex: 2,
                width: 20,
                height: 20,
                borderRadius: '50%',
              }}
              color="inherit"
              aria-label="Menu-close"
              onClick={this.handleToggle}
            >
              <CloseIcon />
            </div>

            {userAuthenticated && userAuthenticated.uid ? (
              <div>
                <Link
                  to="/"
                  style={{
                    display: 'block',
                    border: '0px black solid',
                    marginBottom: 50,
                  }}
                >
                  <img
                    src={logo_primary}
                    style={{ width: 46 }}
                    className="App-logo"
                    alt="logo"
                  />
                </Link>
                <Link
                  to="/profile"
                  style={{ position: "relative", display: "inline-block" , textDecoration: 'none', color: "inherit", border: onSignUpProfileUrl ? "2px solid": 'none', borderRadius: "50px", height:"60px", width:"60px"}}
                  onClick={this.handleClose}
                >
                  <div>
                    <AvatarDisplay
                    imgSrc={userAuthenticated.profile_photo_url}
                    imgClassName={'yogi-photo profile-photo-navbar'}
                    imgAlt={'profile'}
                    userName={userAuthenticated.fullname}
                    textStyles={{letterSpacing: '1px', fontSize: '25px', marginTop: '12px', marginBottom: '13px'}}
                    />
                  </div>
                  <div>
                    <Typography
                      color="textSecondary"
                      style={{ display: 'inline-block' }}
                    >
                      {' '}
                      {(userAuthenticated.fullname &&
                        (userAuthenticated.fullname || '').split(' ')[0]) ||
                        'view profile'}
                    </Typography>
                  </div>
                </Link>
              </div>
            ) : (
              <div>
                <Link to="/" style={{ border: '0px black solid' }}>
                  <img
                    src={logo_primary}
                    style={{ width: 46 }}
                    className="App-logo"
                    alt="logo"
                  />
                </Link>
              </div>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <MenuItem
              onClick={() => {
                history && history.push('/');
                this.handleClose();
              }}
            >
              <Icon>home</Icon>
              <span style={{marginLeft: '5px'}}>Home</span>
            </MenuItem>
            {Boolean(userAuthenticated && userAuthenticated.uid) && (
              <MenuItem
                id="drawer-menu-inbox"
                onClick={() => {
                  history && history.push('/chat');
                  this.handleClose();
                }}
              >
        
                {/* <Icon>inbox</Icon> */}
                <Icon style={{ color: '#1FA3FF', marginRight: '10px' }}>
                  chat
                </Icon>
                <span>Chat</span>
                {/* {Boolean(inbox_unread_count) && (
                  <div
                    style={{
                      backgroundColor: 'orange',
                      // width: 20,
                      marginLeft: 5,
                      padding: '2px 5px',
                      maxHeight: 20,
                      borderRadius: '50%',
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        fontFamily: 'monospace',
                      }}
                    >
                      {inbox_unread_count}
                    </span>
                  </div>
                )} */}
              </MenuItem>
            )}
            {Boolean(userAuthenticated && userAuthenticated.uid) && (
              <MenuItem
                onClick={() => {
                  history && history.push('/schedule');
                  this.handleClose();
                }}
              >
                <Icon style={{ color: '#1FA3FF', marginRight: '10px' }}>
                  schedule
                </Icon>
                <span>Schedule</span>
              </MenuItem>
            )}
            {userAuthenticated && userAuthenticated.uid && (
              <MenuItem
                onClick={() => {
                  history &&
                    history.push('/alerts', {
                      pendingGroupChatMembers,
                      pendingGroupChatInvites,
                    });
                  this.handleClose();
                }}
              >
                
                <NotificationIcon/>
                <span style={{marginLeft: '5px'}}>Alerts</span>
                {(Boolean(pendingGroupChatMembersCount) ||
                  Boolean(pendingGroupChatInvitesCount)) && (
                  <div
                    style={{
                      backgroundColor: '#1FA3FF',
                      // width: 20,
                      marginLeft: 5,
                      padding: '2px 5px',
                      maxHeight: 20,
                      borderRadius: '50%',
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        fontFamily: 'monospace',
                      }}
                    >
                      {pendingGroupChatMembersCount +
                        pendingGroupChatInvitesCount}
                      +
                    </span>
                  </div>
                )}
              </MenuItem>
            )}
            {userAuthenticated && userAuthenticated.teachers && (
              <MenuItem
                onClick={() => {
                  history && history.push('/teachers');
                  this.handleClose();
                }}
              >
                <PersonIcon />
                <span style={{marginLeft: '5px'}}>Instructors</span>
              </MenuItem>
            )}

            {userAuthenticated && userAuthenticated.uid && (
              <MenuItem
                onClick={() => {
                  history &&
                    history.push({ pathname: '/classesmanage', state: {} });
                  this.handleClose();
                }}
              >
                   <SchoolIcon/>
                <span style={{marginLeft: '5px'}}>Manage Classes</span>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                history && history.push('/explore');
                this.handleClose();
              }}
            >
              <Icon>search</Icon>
              <span style={{marginLeft: '5px'}}>Explore</span>
            </MenuItem>

            {userAuthenticated && userAuthenticated.uid && (
              <MenuItem
                onClick={() => {
                  history && history.push('/settings');
                  this.handleClose();
                }}
              >
                <SettingsIcon/>
                <span style={{marginLeft: '5px'}}>Settings</span>
              </MenuItem>
            )}
            {/*
          <MenuItem onClick={() => {
            history.push("/teachers")
            this.handleClose
          }
          }>
            <span>Schedule</span>
          </MenuItem>
           <MenuItem onClick={this.handleClose}>
          <span>Teacher Tools</span>
        </MenuItem>
        <MenuItem onClick={this.handleClose}>
          <span>Inbox</span>
        </MenuItem>
        <MenuItem onClick={this.handleClose}>
          <span>Settings</span>
        </MenuItem> */}
            {/* <MenuItem>
            <span>Help</span>
          </MenuItem> */}

            {user && (
              <MenuItem
                id="logout-from-drawer-btn"
                style={{
                  fontSize: '1rem',
                  marginTop: 50,
                }}
                onClick={() => {
                  this.doLogout();
                }}
              >
                Log out
              </MenuItem>
            )}
            {/*

{" "}
<MenuItem
              // as={Link}
              // to="/signup"
              onClick={() => {
                console.log("History object", history);
                console.log("This.props stuff-", this.props);
                history && history.push("/signup");
                this.handleClose();
              }}
>
Sign up
</MenuItem>
{" "}
 */}

            {/*
            viewingUserHasInstalledApp
            && supportedMobilePhoneDetected
            &&
            <MenuItem>
              <span>Open the app</span>
              <span role="img" aria-label="mobile device">📱</span>
            </MenuItem>
          */}

            {viewingUserHasInstalledApp && supportedMobilePhoneDetected && (
              <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
                <a
                  href={appUrl}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecorationLine: 'none',
                    border: `1px solid ${Colors.sec_color}`,
                    margin: '0 20px',
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  Open in the FavYogis mobile app.
                  <Icon>exit_to_app</Icon>
                </a>
              </Typography>
            )}
            {userAuthenticated && !viewingUserHasInstalledApp && (
              <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
                <a
                  target="blank"
                  href="http://favyogis.com/app/"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px 5px',
                    margin: 'auto 20px',
                    border: `2px solid ${Colors.sec_color}`,
                    color: Colors.pri_color,
                    backgroundColor: '#fff',
                    textDecoration: 'none',
                    // fontWeight: "bold",
                    borderRadius: 4,
                  }}
                >
                  Get the app for more options
                  <Icon>get_app</Icon>
                </a>
              </Typography>
            )}
          </div>
          <div
            style={{
              position: 'relative',
              marginBottom: 10,
              marginTop: 100,
              padding: '5px',
              flex: 0,
            }}
          >
            <span
              style={{
                fontSize: '0.85em',
                color: theme.palette.text.secondary,
              }}
            >
              {UtilFX.getVersion()}
            </span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://favyogis.com/legal"
              style={{
                display: 'flex',
                textDecoration: 'underline',
                fontSize: '0.5em',
                color: theme.palette.text.secondary,
              }}
            >
              Privacy, Terms and Conditions
            </a>
          </div>
        </div>
      </Drawer>
    );
  };

  FYDrawer = this.FYDrawerX;

  appRoutes = (bigProps) => {
    // console.log("props for routes", bigProps);
    // const { context } = bigProps;
    // const { userAuthenticated } = context;
    //const isAuthed = userAuthenticated && userAuthenticated.uid && true;
    const isAuthed = auth.currentUser && true;
    // console.log("is authed?", isAuthed);
    return (
      <Switch>
        <Route
          name="home"
          exact
          path="/"
          render={(props) => (
            <SlimWrapper>
              <Home {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="login"
          exact
          path="/login"
          render={(props) => (
            <Wrapper>
              <LoginSignupForgotAuthUI {...bigProps} {...props} />
            </Wrapper>
          )}
        />
        <Route
          name="signup"
          exact
          path="/signup"
          render={(props) => (
            <Wrapper>
              <LoginSignupForgotAuthUI {...bigProps} {...props} />
            </Wrapper>
          )}
        />
        <Route
          name="forgot"
          exact
          path="/forgot"
          render={(props) => (
            <Wrapper>
              <LoginSignupForgotAuthUI {...bigProps} {...props} />
            </Wrapper>
          )}
        />
        <Route
          exact
          path="/learn-more-teacher"
          render={(props) => (
            <SlimWrapper>
              <LearnMoreTeacher {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          exact
          path="/learn-more"
          render={(props) => (
            <SlimWrapper>
              <LearnMore {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="explore"
          exact
          path="/explore"
          render={(props) => (
            <SlimWrapper>
              <Explore {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="explore"
          exact
          path="/explore/teachers"
          render={(props) => (
            <SlimWrapper>
              <ExploreTeachers {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <PrivateRoute
          name="classesmanage"
          exact
          path="/classesmanage"
          authed={isAuthed}
          {...bigProps}
          component={ClassesManageContainer}
        />
        <PrivateRoute
          name="profile"
          exact
          path="/profile"
          authed={isAuthed}
          {...bigProps}
          component={Profile}
        />
        <PrivateRoute
          name="classeseditnew"
          exact
          path="/classesedit"
          authed={isAuthed}
          {...bigProps}
          component={ClassesEditContainer}
        />
        <PrivateRoute
          name="classesedit"
          exact
          path="/classesedit/:cid"
          authed={isAuthed}
          {...bigProps}
          component={ClassesEditContainer}
        />
        <PrivateRoute
          name="alerts"
          exact
          path="/alerts"
          authed={isAuthed}
          {...bigProps}
          component={Alerts}
        />
        <PrivateRoute
          name="invites"
          exact
          path="/invites"
          authed={isAuthed}
          {...bigProps}
          component={Invites}
        />
        <PrivateRoute
          name="inbox"
          exact
          path="/inbox"
          authed={isAuthed}
          {...bigProps}
          component={Inbox}
        />
        <PrivateRoute
          name="schedule"
          exact
          path="/schedule"
          authed={isAuthed}
          {...bigProps}
          component={Schedule}
        />
        <PrivateRoute
          name="settings"
          exact
          path="/settings"
          authed={isAuthed}
          {...bigProps}
          component={Settings}
        />
        <PrivateRoute
          name="teachers"
          exact
          path="/teachers"
          authed={isAuthed}
          {...bigProps}
          component={Teachers}
        />
        <PrivateRoute
          name="limitsandplans"
          exact
          path="/limitsandplans"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={LimitsAndPlans}
        />
        <PrivateRoute
          name="students"
          exact
          path="/students"
          authed={isAuthed}
          {...bigProps}
          component={Students}
        />
        <PrivateRoute
          name="gallery"
          exact
          path="/gallery"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={ComponentGallery}
        />
        <PrivateRoute
          name="chat"
          exact
          path="/chat"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/dm"
          exact
          path="/chat/dm"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/class"
          exact
          path="/chat/class"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/teacher"
          exact
          path="/chat/teacher"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/bookmark"
          exact
          path="/chat/bookmark"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/archive"
          exact
          path="/chat/archive"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/convo"
          path="/chat/convo/:convo_id"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="chat/bookmark/id"
          path="/chat/bookmark/:convo_id"
          wrapper={Wrapper}
          authed={isAuthed}
          {...bigProps}
          component={NewInbox}
        />
        <PrivateRoute
          name="student"
          path="/student/:userid"
          authed={isAuthed}
          {...bigProps}
          component={ProfileBase}
        />
        <PrivateRoute
          name="earnings"
          path="/earnings"
          authed={isAuthed}
          {...bigProps}
          component={Earnings}
        />
        <PrivateRoute
          name="history"
          path="/history"
          authed={isAuthed}
          {...bigProps}
          component={History}
        />
        <PrivateRoute
          name="deleteaccount"
          path="/deleteaccount"
          authed={isAuthed}
          {...bigProps}
          component={DeleteAccount}
        />
        <Route
          name="yogiclass"
          path="/:username/class/:classid"
          render={(props) => (
            <SlimWrapper>
              <ClassesDetail {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="chatinvite"
          path="/join/:convoid"
          render={(props) => (
            <Wrapper>
              <GroupChatInvitePrompt {...bigProps} {...props} />
            </Wrapper>
          )}
        />
        <Route
          name="add-class"
          path="/add-class"
          render={(props) => (
            <SlimWrapper>
              <ClassesEditContainer {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="yogievent"
          path="/:username/event/:eventid"
          render={(props) => (
            <SlimWrapper>
              <ClassesDetail {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="placedetail"
          path="/place/:placeid"
          render={(props) => (
            <SlimWrapper>
              <PlaceDetail {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
        <Route
          name="yogiteacher"
          path="/:username"
          render={(props) => (
            <SlimWrapper>
              <ProfileBase {...bigProps} {...props} />
            </SlimWrapper>
          )}
        />
      </Switch>
    );
  };

  handleToggle = () => this.setState({ openDrawer: !this.state.openDrawer, notificationAlert: true });

  handleClose = () => this.setState({ openDrawer: false });

  render() {
    const {
      userAuthenticated = {},
      pendingInstagramEmail,
      instagramState,
    } = this.state || {};
    const { classes = {}, history = {}, location = {} } = this.props;
    const locationComingFrom = (location.state || {}).from;
    const currentHistory =
      (window &&
        window.sessionStorage &&
        JSON.parse(window.sessionStorage.getItem('myCustomHistory') || '[]')) ||
      []; //adding in for testing to be able to run
    const lastDirectUrlVisit = currentHistory.slice(-1)[0];
    const samePage = lastDirectUrlVisit === location.pathname;
    const isOnRootPath = location.pathname === '/';
    const isOnExploreRoot = location.pathname === '/explore';
    // console.log("last direct url visit", lastDirectUrlVisit);
    // console.log("vs current location", location.pathname, location.pathname.length, typeof location.pathname);
    // console.log("are they equal. last === current", samePage);

    const canGoForward =
      !isOnExploreRoot && !isOnRootPath && !samePage && currentHistory.length;

    // console.log("incoming app route props", this.props)
    // console.log("incoming cangoforward", canGoForward)

    return (
      <GlobalContext.Provider value={this.state}>
        <MuiThemeProvider theme={themeX}>
          <Grid container direction="column" id="App" className="App">
            <Helmet>
              {/* <!-- for Facebook --> */}
              <meta property="og:title" content="FavYogis" />
              <meta property="og:type" content="website" />
              <meta
                property="og:image"
                content="http://favyogis.com/wp-content/uploads/2017/03/iTunesArtwork.png"
              />
              <meta property="og:url" content="https://favyogis.me/" />
              <meta
                property="og:description"
                content="Simplify your yoga. Private and public class management for yoga."
              />

              {/* <!-- for Twitter --> */}
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:site" content="https://favyogis.me/" />
              <meta name="twitter:title" content="FavYogis" />
              <meta
                name="twitter:description"
                content="Simplify your yoga. Private and public class management for yoga."
              />
              <meta
                name="twitter:image"
                content="http://favyogis.com/wp-content/uploads/2017/03/iTunesArtwork.png"
              />
              <meta name="twitter:url" content="https://favyogis.me/" />
            </Helmet>
            {instagramState === 'app' &&
            Boolean(pendingInstagramEmail) ? null : (
              <AppBar position="static" color="primary">
                <Toolbar>
                  <IconButton
                    id="hamburger-drawer-btn"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={this.handleToggle}
                  >
                    <MenuIcon />
                  </IconButton>

                  <div
                    style={{
                      textAlign: 'right',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Link
                      className="nav-explore"
                      to="/explore"
                      style={{
                        display: 'inline-block',
                        padding: '5px',
                        // height: "35px",
                        margin: 'auto 5px',
                        // border: "2px solid #fff",
                        color: Colors.pri_color,
                        backgroundColor: '#fff',
                        textDecoration: 'none',
                        // fontWeight: "bold",
                        borderRadius: 4,
                      }}
                    >
                      <Icon
                        style={{ verticalAlign: 'middle', fontSize: '15px' }}
                      >
                        search
                      </Icon>
                      Explore
                    </Link>
                    {userAuthenticated && userAuthenticated.uid ? (
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        <Link to="/">
                          <Icon
                            style={{
                              verticalAlign: 'middle',
                              color: '#fff',
                              fontSize: '2em',
                            }}
                          >
                            home
                          </Icon>
                        </Link>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        {/* <Link
                          className="nav-signup"
                          to="/signup"
                          style={{
                            display: "inline-block",
                            padding: "8px 5px",
                            margin: "auto 5px",
                            border: "2px solid #fff",
                            color: Colors.pri_color,
                            backgroundColor: "#fff",
                            textDecoration: "none",
                            fontWeight: "bold",
                            borderRadius: 4,
                          }}
                        >
                          Sign up
                        </Link> */}
                        <Link
                          className="nav-login"
                          to="/login"
                          style={{
                            display: 'inline-block',
                            padding: '5px',
                            margin: 'auto 5px',
                            border: '2px solid #fff',
                            color: '#fff',
                            backgroundColor: 'transparent',
                            textDecoration: 'none',
                            borderRadius: 4,
                          }}
                        >
                          Log in
                        </Link>
                        <Link
                          to="/"
                          style={{
                            display: 'inline-block',
                            padding: '10px 5px',
                          }}
                        >
                          <img
                            src={logo_white}
                            className="App-logo"
                            alt="logo"
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                  {/* <Typography variant="title" color="inherit" className={classes.flex}>
                FavYogis
              </Typography>
                <Button>Login</Button>
                */}
                </Toolbar>
              </AppBar>
            )}
            {/* <Grid container  justify="center" style={{ flex: 1 }}> */}
            <Grid item id="content-hole" style={{ flex: 1 }} xs={12}>
              <div className="nav-direction-holder">
                {
                  <Link
                    test-hook="global_nav_link_back"
                    style={{
                      visibility: locationComingFrom ? 'visible' : 'hidden',
                      fontSize: 50,
                      color: '#fff',
                      textDecoration: 'none',
                      padding: 10,
                      paddingRight: 10,
                      marginLeft: 20,
                      opacity: 0.4,
                    }}
                    to={locationComingFrom || '/'}
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    <Icon>arrow_back_ios</Icon>
                  </Link>
                }
                {
                  <Link
                    test-hook="global_nav_link_forward"
                    to="/"
                    style={{
                      visibility: canGoForward ? 'visible' : 'hidden',
                      fontSize: 50,
                      color: '#fff',
                      textDecoration: 'none',
                      //padding: 10,
                      //paddingLeft: 10,
                      opacity: 0.4,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      // console.log("history currently", history);
                      // console.log("location currently", location);
                      history.goForward();
                    }}
                  >
                    <Icon>arrow_forward_ios</Icon>
                  </Link>
                }
              </div>
              <div id="update-box" style={{ display: 'none' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    backgroundColor: Colors.darkBlue,
                    padding: '18px 18px 10px 18px',
                  }}
                >
                  <div>
                    <p
                      style={{ ...GlobalStyles.bodyMedium, textAlign: 'left' }}
                    >
                      A new version is now available!
                      <br /> Refresh now for a better experience.{' '}
                    </p>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <ButtonComponent
                      value="refresh now"
                      onClick={() => {
                        console.warn(
                          'update global state and/or spark refresh'
                        );
                        window && window.location.reload();
                      }}
                      buttonStyle={{
                        color: Colors.white,
                        borderRadius: '4px',
                        marginLeft: '40px',
                        width: '141px',
                        height: '44px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <ErrorBoundary>
                <this.appRoutes {...this.props} context={this.state} />
              </ErrorBoundary>
            </Grid>
            {/* </Grid> */}
            {/* <div style={{ height: 100, backgroundColor: ['/login', '/signup'].includes(window && window.location.pathname)? Colors.white: 'transparent' }}>

            </div> */}
            {instagramState === 'app' &&
            Boolean(pendingInstagramEmail) ? null : (
              <AppBar
                id="footerContainer"
                position="sticky"
                color="primary"
                style={{
                  bottom: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxHeight: 76,
                }}
              >
                <Grid id="footer" item xs={12}>
                  <div
                    className="footerLine"
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <div style={{ display: 'inline-block', padding: '5px' }}>
                      <a
                        style={{ color: '#fff', textDecoration: 'none' }}
                        target="blank"
                        href="https://favyogis.com/#download-mobile-app"
                      >
                        Get the app
                      </a>
                    </div>
                    <div style={{ display: 'inline-block', padding: '5px' }}>
                      <a
                        style={{ color: '#fff', textDecoration: 'none' }}
                        target="blank"
                        href="http://favyogis.com/faq/"
                      >
                        FAQ
                      </a>
                    </div>
                    <div style={{ display: 'inline-block', padding: '5px' }}>
                      <a
                        style={{ color: '#fff', textDecoration: 'none' }}
                        target="blank"
                        href="http://favyogis.com/terms-and-conditions/"
                      >
                        Privacy, Terms and Conditions
                      </a>
                    </div>
                    <div style={{ padding: '5px' }}>
                      <Link
                        style={{ color: '#fff', textDecoration: 'none' }}
                        to="/learn-more"
                      >
                        Learn more
                      </Link>
                    </div>
                    <div style={{ padding: '5px' }}>
                      <Link
                        style={{ color: '#fff', textDecoration: 'none' }}
                        to="/learn-more-teacher"
                      >
                        For teachers
                      </Link>
                    </div>
                  </div>
                </Grid>
              </AppBar>
            )}
            <this.FYDrawer {...this.props} />
          </Grid>
        </MuiThemeProvider>
      </GlobalContext.Provider>
    );
  }
}

export default withRouter(withTheme(withStyles(stylesProfile)(App)));
